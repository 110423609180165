import React, { useState, useEffect } from 'react';
import { Toast as DevextremeToast } from 'devextreme-react/toast';

function Toast({setParentToastConfig, toastBody}) {
	const [toastMessageType, setToastMessageType] = useState('info')
	const [message, setMessage] = useState('');

	// firstly we have to make sure that the code
	// data and message it has to be consistent
	//listing error codes
	//500, 422, 401, 200, 302, 201, 404, 403
  useEffect(() => {
  	if(toastBody.isVisible){
  		switch(toastBody.status){
	  		case 500:
	  		case 401:
	  		case 404:
			case 400:
	  		case 422:
			case 403:
	  		 	setToastMessageType('error');
	  			break;
			 	case 200:
			 		setToastMessageType('success');
			 		break;
		 		default:
		 			setToastMessageType('error');
 		}
 	}
	if (toastBody?.message?.match(/status code 403/ig)){
		setMessage("Unauthorized: Access denied");
	} else {
		if (toastBody.message === "" || toastBody.message === null || toastBody.message === undefined){
			if (toastBody.status && toastBody.status === 200){
				setMessage('Success')
			} else {
				setMessage('Something went wrong. Please try again later.')
			}
		} else{
			setMessage(toastBody.message);
		}
	}
  }, [toastBody]);

  function onHidingToast() {
    setToastMessageType('info');
    setParentToastConfig({...toastBody,isVisible: false,});
  }

	return (
    <DevextremeToast
    	visible={toastBody?.isVisible}
    	message={message}
    	type={toastMessageType}
    	onHiding={onHidingToast}
    	displayTime={toastBody?.displayTime}
  	/>
  );
}

export default Toast;
