import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
    Button,
    Column,
    Paging,
    Form,
    Item,
    Pager,
    SearchPanel,
    Editing,
    Popup as Popup2
} from 'devextreme-react/data-grid';
import {Popup as JsonPopUp} from 'devextreme-react/popup';
import OverviewService from "../../api/overview.service";
import Toast from 'components/Toast';
// import * as DOMPurify from 'dompurify';
export default function App(props) {
    const [showJsonPopUp, setShowJsonPopUp] = useState(false)
    const [jsonData, setJsonData] = useState('')
    const [data, setData] = useState([])
    const dropDataGridRef = useRef();
    const [loadingText, setloadingText] = useState('Loading...');
    const allowedPageSizes = [5, 10, 'all'];
    const [deleteMsg, setDeleteMsg] = useState("")
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 3000
    });

    function getData() {
        OverviewService.getBalanceData().then((value) => {
            if (value?.data?.data?.length > 0){
                setData(value?.data?.data)
            } else {
                setloadingText("No Data")
            }
        })
    }

    useEffect(() => {
        getData()
    }, []);

    function calculateEmailCellValue(e){
      if (e.emailSent !== undefined){
        if (e.emailSent){
          return "Yes"
        } else {
          return "No"
        }
      }
      // if (e.column.dataField === 'emailSent' && e.rowType !== 'header'){
      //   if(e.data !== undefined){
      //     if(e.data.emailSent !== undefined){
      //       if (e.data.emailSent){
              
      //       }
      //     }
      //   }
      // }
    }

    // const positionEditorOptions = {
    //   items: types,
    //   searchEnabled: true,
    //   value: '',
    //   acceptCustomValue: false,
    //   displayExpr: "name",
    //   valueExpr: "id",
    // };

    // const notesEditorOptions = { height: 100, width:100 };
  
    // const deviceEditorOptions = {
    //   dataSource: devices,
    //   searchEnabled: true,
    //   value: '',
    //   displayExpr: "serialNumber",
    //   valueExpr: "id",
    // };

    function openJsonPopUp(e){
      if (e?.row?.data?.balanceJson){
        setShowJsonPopUp(true)
        setJsonData(e?.row?.data?.balanceJson)
      } else{
        setShowJsonPopUp(true)
        setJsonData('No Data Available')
      }
    }

    function calculateDaysRequestedCellValue(e){
      if (e.daysRequested === undefined){
          return "120"
      }
    }

    function onHidingJsonPopup(){
      setShowJsonPopUp(false)
    }function onHidingJsonPopup(){
      setShowJsonPopUp(false)
    }

    function onRowUpdating(e) {
      let notes= ''
        if (e.newData.notes !== undefined) {
          notes = e.newData.notes
        }
        OverviewService.editBalance(e.oldData._id, notes).then((customer) => {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: customer?.status===200 ? 'success' : 'error',
            status: customer?.data?.status ? customer?.data?.status : customer.status,
            message: customer?.data?.message ? customer.data.message : customer.message,
          });
        })
        getData();
      }

      async function startEdit(index) {
        await dropDataGridRef.current.instance.editRow(index);
      }
      async function editRow(e) {
        await startEdit(e.row.dataIndex);
      }

    return (
        <>
        <DataGrid
            id="transaction-grid"
            ref={dropDataGridRef}
            showColumnLines={false}
            showRowLines={true}
            height={'calc(100vh - 51px)'}
            noDataText={loadingText}
            keyExpr="_id"
            columnAutoWidth={true}
            allowColumnResizing={true}
            wordWrapEnabled={true}
            onRowUpdating={onRowUpdating}
            onColumnsChanging={function (e) {
            if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
                setloadingText('No Data');
            }
            }}
        dataSource={data}>
        <Paging enabled="true" defaultPageSize={100}/>
        <Editing
          allowUpdating={true}
          allowDeleting={false}
          allowAdding={false}
          use-icons={true}
          mode="popup"
          key={'editing'}
        >
          
          <Popup2
            showTitle={true}
            width={500}
            maxHeight={600}
            title="Asset"
          />
          <Form colCount={1}>
            <Item dataField="name" disabled={true}/>
            <Item dataField="email" disabled={true}/>
            <Item dataField="notes"/>
          </Form>
        </Editing>
        <Pager visible={true}
            allowedPageSizes={allowedPageSizes}
        />
        <SearchPanel visible="true" width="240" placeholder="Search..." />
        <Column
          dataField={"requestId"}
          caption={"Request ID"}
        >
        </Column>

        <Column
        dataField={"name"}
        caption={"Name(s)"}
        ></Column>
        
        <Column
          dataField={"email"}
          caption={"Email(s)"}
        ></Column>

        <Column
          dataField={"bankName"}
          caption={"Bank Name"}
        ></Column>

        <Column
          dataField={"date"}
          caption={"Bal Date"}
          dataType="datetime"
          format="EEE MMM dd yyyy, hh:mm a"
        ></Column>

        <Column
        dataField={"accountName"}
        caption={"Account Name"}
      ></Column>
      <Column
        dataField={"mask"}
        caption={"Account Mask"}
      ></Column>
        <Column
        dataField={"subtype"}
        caption={"Account Subtype"}
      ></Column>
      
      <Column
        dataField={"availableBal"}
        caption={"Bal Available"}
      ></Column>
      <Column
        dataField={"currentBal"}
        caption={"Bal Current"}
      ></Column>
      <Column
        dataField={"accountLimit"}
        caption={"Account Limit"}
      ></Column>
      <Column type="buttons" caption={"View Json"}>
        <Button text="View Json" cssClass="viewJson" onClick={openJsonPopUp} />
      </Column>
      <Column
        dataField={"responseCode"}
        caption={"Response Code"}
      ></Column>
      <Column
        dataField={"comments"}
        caption={"Comments"}
      ></Column>
      <Column
        dataField={"notes"}
        caption={"Notes"}
      ></Column>
      <Column type="buttons" width={110} key={'actionButtons'}>
          <Button name="edit" onClick={editRow} />
        </Column>
      </DataGrid>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      <JsonPopUp visible={showJsonPopUp}
        onHiding={onHidingJsonPopup}
        resizeEnabled={true}
        dragEnabled={true}
        hideOnOutisdeClick={true}
        showCloseButton={true}
        showTitle={true}
        container='.dx-viewport'
        width={1000}
        height={750}>
          <div>{jsonData}</div>
      </JsonPopUp>
      </>
    );
}
