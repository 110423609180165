import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import {Popup as PdfPopUp} from 'devextreme-react/popup';
import {Popup as JsonPopUp} from 'devextreme-react/popup';
import {Position, Popup as ColumnChoserPopUp} from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { CheckBox } from 'devextreme-react/check-box';
import { custom } from 'devextreme/ui/dialog';
import DataGrid, {
    Editing,
    Column,
    Paging,
    Pager,
    Form,
    Item,
    SearchPanel,
    Popup as Popup2,
    // ColumnChooser,
    Button
} from 'devextreme-react/data-grid';
import OverviewService from "../../api/overview.service";
import Toast from 'components/Toast';
import './style.css';
export default function App(props) {
    const [data, setData] = useState([])
    // const [totalRecords, setTotalRecords] = useState(0)
    const totalRecords = 100
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const dropDataGridRef = useRef();
    const [loadingText, setloadingText] = useState('Loading...');
    const allowedPageSizes = [25, 50, 'all'];
    const [showPdfPopUp, setShowPdfPopUp] = useState(false)
    const [jsonUserTitle, setJsonUserTitle] = useState("")
    const [forceUpdate, setForceUpdate] = useState(false)
    const [showJsonPopUp, setShowJsonPopUp] = useState(false)
    const [visibleColumnChooser, setVisibleColumnChooser] = useState(true)
    const [jsonData, setJsonData] = useState('')
    const [popUpFile, setPopupFile] = React.useState("")
    const [fileName, setFileName] = React.useState("")
    // const [showPdfBtn, setShowPdfBtn] = React.useState(false)
    const [showColumnChooserPopUp, setShowColumnChooserPopUp] = React.useState(false)
    // const [deleteMsg, setDeleteMsg] = useState("")
    const [toastConfig, setToastConfig] = React.useState({
        isVisible: false,
        type: 'info',
        message: '',
        displayTime: 5000
    });
    const tenant = localStorage.getItem('user')
    function selectColumn(name) {
      var storedArray = JSON.parse(localStorage.getItem(tenant + "visibleCols"));
      if (storedArray === null) {
        storedArray = []
        storedArray.push({
          name: name,
          value: true
        })
        localStorage.setItem(tenant + "visibleCols", JSON.stringify(storedArray));
      } else {
        let found = false
        for (let i = 0; i < storedArray.length; i++) {
          if (storedArray[i].name === name) {
            storedArray[i].value = !storedArray[i].value
            found = true
          }
        }
        if (!found) {
          storedArray.push({
            name: name,
            value: true
          })
        }
        localStorage.setItem(tenant + "visibleCols", JSON.stringify(storedArray));
      }
      setForceUpdate(!forceUpdate)
    }

    function chooserColumn() {
      return (
        <>
          <ScrollView width='100%' height='100%'>
            <div className='assets-columnChooserContainer'>
            <div className='assets-columnCheckbox' onClick={() => { selectColumn("requestId") }}>
                <CheckBox value={showColumn("requestId")} disabled={true} />
                &nbsp; Request ID
              </div>
              <div className='assets-columnCheckbox' onClick={() => { selectColumn("address") }}>
                <CheckBox value={showColumn("address")} disabled={true} />
                &nbsp; Addresses
              </div>
              <div className='assets-columnCheckbox' onClick={() => { selectColumn("phoneNumber") }}>
                <CheckBox value={showColumn("phoneNumber")} disabled={true} />
                &nbsp; Phone(s)
              </div>
              {/* <div className='assets-columnCheckbox' onClick={() => { selectColumn("accounts") }}>
                <CheckBox value={showColumn("accounts")} disabled={true} />
                &nbsp; Account(s)
              </div> */}
              <div className='assets-columnCheckbox' onClick={() => { selectColumn("daysRequested") }}>
                <CheckBox value={showColumn("daysRequested")} disabled={true} />
                &nbsp; Days Requested
              </div>
              <div className='assets-columnCheckbox' onClick={() => { selectColumn("responseString") }}>
                <CheckBox value={showColumn("responseString")} disabled={true} />
                &nbsp; Response Code
              </div>
              <div className='assets-columnCheckbox' onClick={() => { selectColumn("comments") }}>
                <CheckBox value={showColumn("comments")} disabled={true} />
                &nbsp; Comments
              </div>
              <div className='assets-columnCheckbox' onClick={() => { selectColumn("isAccessTokenRevoked") }}>
                <CheckBox value={showColumn("isAccessTokenRevoked")} disabled={true} />
                &nbsp; Token Removed
              </div>
            </div>
          </ScrollView>
        </>
      )
    }
  
    function showColumn(colName) {
      if (localStorage.getItem(tenant + "visibleCols") !== undefined && localStorage.getItem(tenant + "visibleCols") !== null) {
        var storedArray = JSON.parse(localStorage.getItem(tenant + "visibleCols"));
        let found = false
        for (let i = 0; i < storedArray.length; i++) {
          if (storedArray[i].name === colName) {
            found = true
            return storedArray[i].value
          }
        }
        if (!found) {
          storedArray.push({
            name: colName,
            value: false
          })
          localStorage.setItem(tenant + "visibleCols", JSON.stringify(storedArray));
          return false
        }
      } else {
        var storedArray2 = []
        storedArray2.push({
          name: colName,
          value: false
        })
        localStorage.setItem(tenant + "visibleCols", JSON.stringify(storedArray2));
        return false
      }
    }

    function confirmDailogReport(e){
        let email = e.row.data?.email ? e.row.data.email : ''
        let myDialog = custom({
          title: "Run Asset Report",
          messageHtml: `<b>Are you sure you want to Run Asset report for ${email}?</b>`,
          buttons: [{
              text: "Cancel",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },
          {
            text: "Confirm",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        },
          ]
      });
      myDialog.show().then((dialogResult) => {
          console.log(dialogResult);
          if(dialogResult.buttonText === "Confirm"){
            OverviewService.runAssetReport(e.row.data._id, e?.row?.data?.accessToken, e?.row?.data?.assetReportToken)
            .then((res) => {
              if(res.data){
                setToastConfig({
                  isVisible: true,
                  status: res.data.status,
                  message: res.data.message
                });
              } else{
                setToastConfig({
                  isVisible: true,
                  status: 400,
                  message: 'Something went wrong. Please try again later.'
                });
              }
              getData()
              myDialog.hide()
            }).catch((er) =>{
              setToastConfig({
                isVisible: true,
                status: 400,
                message: 'Something went wrong. Please try again later.'
              });
              myDialog.hide()
            })
          } else {
            myDialog.hide()
          }
      });
    }

    function confirmDialogBalance(e){
      let email = e.row.data?.email ? e.row.data.email : ''
        let myDialogBalance = custom({
          title: "Run Asset Report",
          messageHtml: `<b>Are you sure you want to Run Balance for ${email}?</b>`,
          buttons: [{
              text: "Cancel",
              onClick: (e) => {
                  return { buttonText: e.component.option("text") }
              }
          },
          {
            text: "Confirm",
            onClick: (e) => {
                return { buttonText: e.component.option("text") }
            }
        },
          ]
      });
      myDialogBalance.show().then((dialogResult) => {
          console.log(dialogResult);
          if(dialogResult.buttonText === "Confirm"){
            OverviewService.runBalanceReport(e.row.data._id, e?.row?.data?.accessToken, e?.row?.data?.fullName, e?.row?.data?.email)
            .then((res) => {
              if(res.data){
                setToastConfig({
                  isVisible: true,
                  status: res.data.status,
                  message: res.data.message
                });
              } else{
                setToastConfig({
                  isVisible: true,
                  status: 400,
                  message: 'Something went wrong. Please try again later.'
                });
              }
              getData()
              myDialogBalance.hide()
            }).catch((er) =>{
              setToastConfig({
                isVisible: true,
                status: 400,
                message: 'Something went wrong. Please try again later.'
              });
              myDialogBalance.hide()
            })
          } else {
            myDialogBalance.hide()
          }
      });
    }

    // function runReport(e){
    //   OverviewService.runAssetReport(e.row.data._id, e?.row?.data?.accessToken, e?.row?.data?.assetReportToken)
    //   .then((res) => {
    //     if(res.data){
    //       setToastConfig({
    //         isVisible: true,
    //         status: res.data.status,
    //         message: res.data.message
    //       });
    //     } else{
    //       setToastConfig({
    //         isVisible: true,
    //         status: 400,
    //         message: 'Something went wrong. Please try again later.'
    //       });
    //     }
    //     getData()
    //   }).catch((er) =>{
    //     setToastConfig({
    //       isVisible: true,
    //       status: 400,
    //       message: 'Something went wrong. Please try again later.'
    //     });
    //   })
    // }

    // function runBalance(e){
    //   OverviewService.runBalanceReport(e.row.data._id, e?.row?.data?.accessToken, e?.row?.data?.fullName, e?.row?.data?.email)
    //   .then((res) => {
    //     if(res.data){
    //       setToastConfig({
    //         isVisible: true,
    //         status: res.data.status,
    //         message: res.data.message
    //       });
    //     } else{
    //       setToastConfig({
    //         isVisible: true,
    //         status: 400,
    //         message: 'Something went wrong. Please try again later.'
    //       });
    //     }
    //     getData()
    //   }).catch((er) =>{
    //     setToastConfig({
    //       isVisible: true,
    //       status: 400,
    //       message: 'Something went wrong. Please try again later.'
    //     });
    //   })
    // }

    function getUserJsonData(e){
      OverviewService.getUserJsonData(e.row.data._id)
      .then((res) => {
        setJsonData(res.data.data.userData)
      }).catch((er) =>{
        
      })
    }

    function getData() {
      OverviewService.getOverviewData().then((value) => {
        if (value?.data?.data?.length > 0){
          setData(value?.data?.data)
          // setTotalRecords(value?.data?.totalCount);
          setCurrentPage(1);
          setPageSize(25)
        } else {
          setloadingText("No Data")
          // setTotalRecords(0);
        }
      })
    }

    useEffect(() => {
        getData()
    }, []);

    function onHiding(){
       
      setShowPdfPopUp(false)
      setPopupFile('')
    }

    function onHidingJsonPopup(){
      setShowJsonPopUp(false)
      setJsonUserTitle('')
      setJsonData('')
    }

    function onHidingColumnChooserPopup(){
      setShowColumnChooserPopUp(false)
    }

    function openPdfPopUp(e){
      if (e?.row?.data?.filePath){
        const fileUrl = e?.row?.data?.filePath
        const data = fileUrl.split('/')
        const fileName = data[data.length - 1]
        setShowPdfPopUp(true)
        setFileName(fileName)
        setPopupFile(e?.row?.data?.filePath)
      } else{
        setFileName('')
        setShowPdfPopUp(true)
      }
    }

    function openJsonPopUp(e){
      setShowJsonPopUp(true)
      setJsonUserTitle(`User Data - ${e.row.data.fullName}`)
      OverviewService.getUserJsonData(e.row.data._id)
      .then((res) => {
        setJsonData(res.data.data.userData)
      }).catch((er) =>{
        setJsonData('No Data Available')
      })
    }

    function calculateEmailCellValue(e){
      if (e.emailSent !== undefined){
        if (e.emailSent){
          return "Yes"
        } else {
          return "No"
        }
      }
    }

    async function startEdit(index) {
      await dropDataGridRef.current.instance.editRow(index);
    }
    async function editRow(e) {
      await startEdit(e.row.dataIndex);
    }

    function calculateDaysRequestedCellValue(e){
      if (e.daysRequested === undefined){
          return "120"
      }
    }

    function calculateTokenRevokedCellValue(e){
      if (e.isAccessTokenRevoked !== undefined){
        if (e.isAccessTokenRevoked){
          return "Yes"
        } else {
          return "No"
        }
      } else {
        return "No"
      }
    }

    function columnChooser(e) {
      setVisibleColumnChooser(!visibleColumnChooser)
      setShowColumnChooserPopUp(true)
      localStorage.setItem("visibleColumnChooser", !visibleColumnChooser)
    }

    const handlePageChange = (newPageIndex) => {
      setCurrentPage(newPageIndex + 1);
    };

    function calculateAddressCellValue(e){
      if(e.address){
        const address = JSON.parse(e.address)
        return `City: ${address.city}
        Country: ${address.country}
        Postal Code: ${address.postal_code}
        State: ${address.region}
        Street: ${address.street}
        `
      } else {
        return `-`
      }
    }

    function onRowUpdating(e) {
      let notes= ''
        if (e.newData.notes !== undefined) {
          notes = e.newData.notes
        }
      OverviewService.editCustomer(e.oldData._id, notes).then((customer) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          type: customer?.status===200 ? 'success' : 'error',
          status: customer?.data?.status ? customer?.data?.status : customer.status,
          message: customer?.data?.message ? customer.data.message : customer.message,
        });
      })
      getData()
    }

    return (
        <>
        <DataGrid
            id="overview-grid"
            ref={dropDataGridRef}
            showColumnLines={false}
            showRowLines={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            height={'calc(100vh - 51px)'}
            noDataText={loadingText}
            keyExpr="_id"
            paginate= {true} // Enable pagination
            pageSize= {pageSize}
            // onOptionChanged={handleOptionChange}
            remoteOperations={{ paging: true }}
            onRowUpdating={onRowUpdating}
            wordWrapEnabled={true}
            onColumnsChanging={function (e) {
            if(e.component?.getDataSource()?._totalCount <= 0 && data.length > 0){
              setloadingText('No Data');
            }
            }}
            
            onInitialized={(e) => {
              e.component.pageIndex(currentPage - 1); // DevExtreme uses 0-based index, so subtract 1
            }}
            onContentReady={handlePageChange}
            
           dataSource={data}>
        <Paging
    defaultPageSize={pageSize}
    pageSize={pageSize}
    pageIndex={currentPage - 1} // DevExtreme uses 0-based index, so subtract 1
    totalCount={totalRecords} // Set the total number of records
    onPageChanged={handlePageChange}
  />
        <Pager visible={true}
            allowedPageSizes={allowedPageSizes}
            showNavigationButtons={true}
            showInfo={true}
            pagerProps={{
            maxPagesDisplayed: 4
            }}
            displayMode={'full'}
        />
        <Editing
          allowUpdating={true}
          allowDeleting={false}
          allowAdding={false}
          use-icons={true}
          mode="popup"
          key={'editing'}
        >
          
          <Popup2
            showTitle={true}
            width={500}
            maxHeight={600}
            title="Asset"
          />
          <Form colCount={1}>
            <Item dataField="name" disabled={true}/>
            <Item dataField="email" disabled={true}/>
            <Item dataField="notes"/>
          </Form>
        </Editing>
        <SearchPanel visible="true" width="240" placeholder="Search..." />
        <Column
        visible={showColumn('requestId')}
          dataField={"requestId"}
          caption={"Request ID"}
        >
        </Column>
        <Column
          dataField={"fullName"}
          caption={"Name(s)"}
        >
        </Column>

        <Column
        dataField={"institutionName"}
        caption={"Bank Name"}
        // width={60}
        ></Column>
        
        <Column
          dataField={"email"}
          caption={"Email(s)"}
          // width={60}
        ></Column>
        <Column
          visible={showColumn('address')}
          dataField={"address"}
          caption={"Addresses"}
          calculateCellValue={calculateAddressCellValue}
        ></Column>
        <Column
          visible={showColumn('phoneNumber')}
          dataField={"phoneNumber"}
          caption={"Phone(s)"}
        ></Column>
        <Column
        dataField={"reportDate"}
        caption={"Asset Date"}
        dataType="datetime"
        format="EEE MMM dd yyyy, hh:mm a"
      ></Column>
        <Column
          visible={showColumn('daysRequested')}
          dataField={"daysRequested"}
          caption={"Days Requested"}
          value="120"
          calculateCellValue={calculateDaysRequestedCellValue}
        ></Column>
        <Column
          visible={showColumn('isAccessTokenRevoked')}
          dataField={"isAccessTokenRevoked"}
          caption={"Token Removed"}
          calculateCellValue={calculateTokenRevokedCellValue}
        ></Column>
      
      <Column
        dataField={"emailSent"}
        caption={"PDF Sent"}
        calculateCellValue={calculateEmailCellValue}
      ></Column>
      <Column
        visible={showColumn('responseString')}
        dataField={"responseString"}
        caption={"Response Code"}
      ></Column>
      <Column type="buttons"
        caption={"Open PDF"}
      >
        <Button text="Open PDF" cssClass="openPdf" onClick={openPdfPopUp} />
      </Column>

      <Column type="buttons" caption={"View Json"}>
        <Button text="View Json" cssClass="viewJson" onClick={openJsonPopUp} />
      </Column>

      <Column type="buttons" caption={"Run Asset Report"}>
        <Button cssClass="runReport" text="Report" onClick={confirmDailogReport} />
      </Column>

      <Column type="buttons" caption={"Run Balance"}>
        <Button text="Balance" cssClass="runBalance" onClick={confirmDialogBalance} />
      </Column>

      <Column
        visible={showColumn('comments')}
        dataField={"comments"}
        caption={"Comments"}
        wordWrapEnabled={false}
        width={60}
      ></Column>
      <Column
        dataField={"notes"}
        caption={"Notes"}
      ></Column>
      <Column type="buttons" width={110} key={'actionButtons'}>
        <Button name="edit" onClick={editRow} />
      </Column>
      {/* <RemoteOperations groupPaging={true} /> */}
      </DataGrid>
      <div className="columnChooser" title={"Choose columns"} onClick={columnChooser} key={'uploadbtnassets2linker'}>
        <i className="dx-icon-columnchooser" style={{ "fontSize": "24px" }}></i>
      </div>
      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
      <PdfPopUp
        visible={showPdfPopUp}
        onHiding={onHiding}
        resizeEnabled={true}
        dragEnabled={true}
        hideOnOutisdeClick={true}
        showCloseButton={true}
        showTitle={true}
        container='.dx-viewport'
        width={1000}
        height={750}
      >
        {fileName ? 
        <object>
          <embed src={`https://admin-api.sundancemedicalfinancing.com/pdf/${fileName}`} type='application/pdf' width="1000" height="800"></embed>
          <p>This browser does not support PDFs. Please download the PDF to view it: <a href={popUpFile}>Download PDF</a>.</p>
        </object> : <h5 style={{textAlign: 'center'}}>No Data Available</h5>
        }
      </PdfPopUp>
      <JsonPopUp visible={showJsonPopUp}
        onHiding={onHidingJsonPopup}
        resizeEnabled={true}
        dragEnabled={true}
        hideOnOutisdeClick={true}
        title={jsonUserTitle}
        showCloseButton={true}
        showTitle={true}
        container='.dx-viewport'
        width={1000}
        height={750}>
          <div>{jsonData}</div>
      </JsonPopUp>
      <ColumnChoserPopUp
      visible={showColumnChooserPopUp}
      onHiding={onHidingColumnChooserPopup}
      resizeEnabled={true}
      dragEnabled={true}
      hideOnOutisdeClick={true}
      showCloseButton={true}
      showTitle={true}
      container='.dx-viewport'
      title={"Column Chooser"}
      maxHeight={400}
        width={250}
        shading={false}
      >
        <Position
          at="right top"
          my="right top"
          offset="-200 160"
        />
        {chooserColumn()}
      </ColumnChoserPopUp>
      </>
    );
}