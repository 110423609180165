import { withNavigationWatcher } from './contexts/navigation';
import { OverviewPage, TransactionPage } from './pages';

const routes = [
  {
    path: '/asset',
    component: OverviewPage
  },
  {
    path: '/balance',
    component: TransactionPage
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
