import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm} from './components';
import Logo from '../src/assets/logo.png';
export default function UnauthenticatedContent() {
  return (
    <Switch>
      <Route exact path='/login' >
        <div className='logo_img'>
          <img src={Logo} alt='Sundance Medical Finance'/>
        </div>
        <SingleCard title="Sign In">
          <LoginForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}
