import * as Axios from "./axios.service";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getOverviewData(){
        return Axios.get(
          `/api/data/asset`,
        );
    },
    getBalanceData(){
      return Axios.get(
        "/api/data/balance",
      );
  },
    runAssetReport(user,accessToken, reportToken){
      return Axios.post(
        "/api/report",
        {
          user: user,
          access_token: accessToken,
          report_token: reportToken
        }
      )
    },
    getUserJsonData(user){
      return Axios.post(
        "/api/user/json",
        {
          user: user
        }
      )
    },
    runBalanceReport(user,accessToken, name, email){
      return Axios.post(
        "/api/balance",
        {
          user: user,
          access_token: accessToken,
          name: name,
          email: email
        }
      )
    },
    editBalance(user,note){
      return Axios.post(
        "/api/balance/edit",
        {
          _id: user,
          notes: note
        }
      )
    },
    editCustomer(user,note){
      return Axios.post(
        "/api/customer/edit",
        {
          _id: user,
          notes: note
        }
      )
    }


};