import axios from "axios";
const avatarUrl = 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/employees/06.png';

const getToken = () => {
  return localStorage.getItem('token');
}

const getUser1 = () => {
  const user = localStorage.getItem('user');
  if (user) {
    return user;
  }
  return false;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  _user: null,

  loggedIn() {
    return !!getToken();
  },

  async signIn(email, password) {
    try {
      await axios.post('/api/user/login', { email, password }).then((res) => {
        let accessToken = res.data.data.token
        let user = res.data.data.email
        const menu = [{"icon":"home","path":"/asset","text":"Asset"}, {"icon":"money","path":"/balance","text":"Balance"}]
        localStorage.setItem('token', accessToken);
        localStorage.setItem('menu', JSON.stringify(menu));
        localStorage.setItem('user', JSON.stringify(user));
        this._user = { ...user, avatarUrl };
      }
      );
      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },

  async getUser() {
    try {
      const user = getUser1();
      if (!user) {
        return {
          isOk: false
        };
      }
      this._user = { user, avatarUrl };
      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async changePassword(password, recoveryCode) {
    try {
      let message, isOk;
      await axios.post('/api/user/set-password', 
      {
        password: password,
        token: recoveryCode
      }).then((res) => {
        message = res.data.message
        isOk = true
      }).catch((er) => {
        if (er && er.response && er.response.data && er.response.data.message){
          message = er.response.data.message
          isOk = false
        }
      });
      return {
        isOk: isOk,
        message: message
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to update password"
      }
    }
  },

  async resetPassword(email) {
    try {
      let message, isOk;
      await axios.post('/api/user/forgot-password', { email }).then((res) => {
        message = res.data.message
        isOk = true
      }
      ).catch((er) => {
        if (er && er.response && er.response.data && er.response.data.message){
          message = er.response.data.message
          isOk = false
        }
      });
      return {
        isOk: isOk,
        message: message
      };
    }
    catch(err) {
      return {
        isOk: false,
        message: "Something went wrong. Please try again later."
      };
    }
  }

}
